
.student-wrapper {
    .header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        background: #fff;
        position: relative;
        .header-time {
            display: flex;
            flex: 1;
            height: 100%;
            justify-content: center;
            align-items: center;
            &::v-deep span {
                font-size: 18px;
                color: #E21D1A;
            }
        }

        .train-manage {
            border: 1px solid #FD4446;
            padding: 10px 11px;
            position: absolute;
            left: 60px;
            top: 22px;
            color: #FD4446;

            &:hover {
                color: #fd4446d9;
                background-color: #fff;
                border: 1px solid #fd4446d9;
            }
        }

        .header-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: 30px;
            line-height: 80px;

            .user-info {
                display: flex;
                align-items: center;

                .user-avatar {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: 50%;
                    border: 1px solid #eee;
                    box-sizing: border-box;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                .user-name {
                    margin-left: 6px;
                }
            }

            .exit-btn {
                margin-left: 24px;
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: all .3s;

                .iconfont {
                    font-size: 20px;
                }

                span {
                    margin-left: 6px;
                }

                &:hover {
                    color: #FD4446;
                }
            }
        }
    }

    .container {
        width: 100%;
        height: calc(100vh - 60px);
        background: url("../../assets/images/studentIndex/index-bg.png") no-repeat bottom center, linear-gradient(#04BEFE, #4482EB);
        background-size: cover;
        position: relative;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
            }
        }

        .multiple-content {
            .dialog-box-shadow {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 999;
                cursor: not-allowed;
            }
            .competition-title {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                .competition-txt {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                &::v-deep {
                    h4 {
                        font-size: 24px;
                        color: #FFF;
                        margin: 20px 0px;
                    }
                    p {
                        color: #FFF;
                        margin: 0;
                        padding: 0;
                        width: 500px;
                        text-align: left;
                        font-size: 18px;
                    }

                }
            }
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .multiple-box {
                height: 70%;
                display: flex;
                /*max-height: 450px;*/
                .mul-item {
                    border-radius: 20px;
                    background-color: #EDFDFF;
                    background-repeat: no-repeat;
                    background-position: bottom center;
                    overflow: hidden;

                    .mul-title {
                        color: #333;
                        font-size: 30px;
                        text-align: center;
                        margin: 70px 0;
                        font-weight: 500;
                    }

                    .img-box {
                        margin: 0 30px 30px;
                    }
                }

                &.box-two {
                    .mul-item {
                        /*width: 34%;*/
                        /*margin: 0 8%;*/
                        /*background-size: auto 63%;*/
                        /*width: calc(50% - 60px);*/
                        //width: 50%;
                        margin-right: 45px;
                        background-size: auto 60%;

                        &:last-child {
                            margin-right: 0;
                            margin-left: 45px;
                        }
                    }
                }
            }
        }
    }
}

::v-deep .end-dialog {
    .el-dialog__header {
        border-bottom: 1px solid #eee;
    }

    .el-dialog__headerbtn:focus .el-dialog__close,
    .el-dialog__headerbtn:hover .el-dialog__close {
        color: #FD4446;
    }

    .end-competition {
        text-align: center;

        img {
            height: 170px;
        }

        .text {
            margin-top: 28px;
            color: #666;
            font-size: 18px;
        }
    }
}
